import React, { useEffect , useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './error.css';
// import ErrorPage from './ErrorPage';
// if using ErrorPage then <Route path="*" element={<Home />} />


function App() {
  return (
    <Router>
      <div className="container">
        <Routes>
          { /* <Route path="/" element={<Home />} />  */}
          <Route path="/video" element={<VideoPlayer />} />
          <Route path="*" element={<Home />} />
          <Route path="/📺" element={<VideoEnded />} />
        </Routes>
      </div>
    </Router>
  );
}

function Home() {
  const [videoUrl, setVideoUrl] = useState('');
  const navigate = useNavigate();

  const handlePlayClick = () => {
    navigate(`/video?url=${videoUrl}`);
  };

  return (
    <div className="home">
      <div className="fullscreen-bg">
        
      </div>
      <h1 className="home__title"><b></b></h1>
      <p className="home__description"></p>
      <div className="home__form">
        <input type="text" className="form-control" placeholder="Enter Video URL" onChange={(e) => setVideoUrl(e.target.value)} />
        <button className="btn btn-primary" onClick={handlePlayClick}>📺 Play</button>
      </div>
      {/* <Header /> */}
    </div>
  );
}

function VideoPlayer() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const videoUrl = query.get('url');

  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (!videoUrl) {
      navigate('/');
    }
  }, [navigate, videoUrl]);

  const handlePlay = () => {
    setPlaying(true);
  };

  const handleProgress = (state) => {
    if (state.played >= 1) {
      navigate('/📺');
    }
  };

  return (
    <div className="video-player">
      <ReactPlayer
        url={videoUrl}
        playing={playing}
        onPlay={handlePlay}
        onProgress={handleProgress}
        controls
        config={{
          file: {
            attributes: {
              crossOrigin: "true",
              controlsList: "nodownload",
            },
          },
        }}
        // light="true" //video will not auto play.
        // loop
        // light="https://via.placeholder.com/800x450/0f0f0e/FFFFFF?text="
      />
      {/* <Header />
      
      <div className="footer">
        <p>&copy; 2023 My Website</p>
  </div> */}
    </div>
  );
}

const VideoEnded = () => {
  return (
    <div className="error-page">
      { /* <h1>404 - Page Not Found</h1> */ }
      <p><b>The video stream is now over!</b></p>
      { /* <button onClick={() => window.location.href = '/'}>Go to Home Page</button> */ }
      { /* <Link to="/">Go to Home Page</Link> */ }
    </div>
  );
}
// function Header() {
//   return (
//     <div className="header">
//       <h3>📺 Online Video Player</h3>
//       <p><b></b></p>
//     </div>
//   );
// }



export default App;
